import React from "react";
import { Layout } from "../components/layout/Layout";
import { Seo } from "../components/seo";
import { Button } from "../components/button/Button";
import "./404.scss";
import snail from "../images/snail.webp";
import { getLanguage } from "../utils/Localization";
import { Link } from "gatsby";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  const language = getLanguage();

  return (
    browser && (
      <Layout>
        <Seo title="404: Not found" />
        <div className="not-found">
          <img width="480" src={snail} alt="404" />
          <h1>Swoosh! The page just disappeared.</h1>
          <p>
            The internet is vast, and wherever you go, you&apos;re there. Now
            you&apos;ve ended up here, and unfortunately, there&apos;s nothing
            here.
            <br />
            <br />
            Most likely, the page has been removed or moved, and for that, we
            apologize.
          </p>
          <Button variant="secondary">
            <Link to={`/${language}/`}>Return to homepage</Link>
          </Button>
        </div>
      </Layout>
    )
  );
};

export default NotFoundPage;
